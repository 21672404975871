//$size-1: 96px; // 50
//$size-2: 48px; // 25

$size-1: 50px; // 50
$size-2: 35px; // 25
$size-3: 25px;

@import "../bulma/bulma";

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  .main {
    flex: 1;
    @include mobile {
      padding-top: 0;
    }
  }
}
